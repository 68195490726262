@import '../../../assets/styles/style-params';
.bg-grey {
  background: $color-06-light-grey;
}
.hardship-plan-info .well {
  margin: 0;
  div {
    width: 100%;
    float: left;
    span {
      width: 165px;
      float: left;
    }
    span:last-child {
      width: calc(100% - 165px);
      float: left;
    }
    :last-child {
      text-align: right;
    }
  }
}
.unable-to-pay {
  margin-top: 5px;
  text-align: left !important;
}
.hardship-plan-info {
  .payment-header {
    @include font-heading-level-4-dark;
    padding-bottom: 5px;
    span {
      margin-right: 10px;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .date-picker {
    width: 100%;
    padding-left: 45px;
  }

  .position-rel {
    position: relative;
  }
}

@media screen and (max-width: 600px) {
  .hardship-plan-info {
    border: none;
    margin: 0 auto 20px;
    width: 100%;
    .account-header {
      @include font-heading-level-6-dark;

      span {
        //margin-right: 5px;

        img {
          height: 15px;
          float: right;
        }
      }
    }
    .amt-Details {
      display: none;
      span {
        margin-right: 5px;
      }
    }
    .cancel-button {
      width: 100% !important;
      margin-bottom: 0;
    }
    .hardship-button {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
  .cancel-button {
    margin-bottom: 20px;
    width: 100%;
  }
  .hardship-button {
    width: 100%;
  }
  .payment-card .inner-block div {
    width: 100%;
    float: left;
    span {
      width: 50%;
      float: left;
    }
    :last-child {
      text-align: right;
    }
  }
  .hardship-plan-info .payment-header span {
    margin-right: 0;
    width: 100%;
  }
}

// @media (min-width: 601px) and (max-width: 786px) {
//   .hardship-plan-info {
//     .cancel-button {
//       margin-bottom: 10px;
//       width: 49%;
//       margin-right: 1%;
//     }
//     .hardship-button {
//       margin-bottom: 10px;
//       width: 49%;
//       margin-right: 1%;
//     }
//   }
// }
