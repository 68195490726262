@import '../../assets/styles/style-params';

.manage-payments-card {
  background: $color-07-white;
  border-radius: 3px;
  box-shadow: 0px 2px 6px #0404042e;
  margin: 60px auto;
  padding: 90px;
  text-align: left;
  width: 100%;
  .payment-detail-block {
    padding: 15px;
    box-shadow: 0px 2px 6px #0404042e;
    border-radius: 3px;
    margin-bottom: 30px;
    min-height: 70px;
    overflow: auto;
    span {
      line-height: 45px;
    }
  }

  h3 {
    @include font-heading-level-5-dark;
  }
  .manage-payments-button {
    margin-top: -55px;
    width: auto;
    margin-right: 0;
  }
}
@media screen and (max-width: 1200px) {
  .manage-payments-card {
    .one-column-block.max-width-block {
      max-width: 100%;
      padding: 0;
    }
  }
}

@media screen and (max-width: 989px) {
  .manage-payments-card {
    margin: 60px auto;
    padding: 25px;
  }
}
@media screen and (max-width: 600px) {
  .manage-payments-card .manage-payments-button {
    margin: 20px auto;
    width: 100%;
  }
}
