@import '../../assets/styles/style-params';
.header-row {
  background-color: $color-07-white;
  display: block;
  max-width: 100%;
  width: 100%;
}
.logout-header {
  display: block;
}
.navbar {
  background: $color-07-white;
  padding: 0.5rem 0 0.5rem 0 !important;
  height: 120px;
  img {
    padding: 15px 0;
  }
  span:last-child {
    padding-top: 0.5rem;
  }
  .signout-button {
    @include font-link-text-in-paragraph;
    background: $color-07-white;
    border: 1px solid $color-04-grey;
    border-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    text-decoration: none;
  }
  .signout-button:hover,
  .signout-button:active,
  .signout-button:focus {
    box-shadow: 2px 2px 4px 0 $color-06-light-grey, -2px -2px 4px 0 $color-06-light-grey;
  }
}
@media screen and (max-width: 1200px) {
  .navbar {
    background: $color-07-white;
    padding: 10px 50px !important;
    span:last-child {
      margin-right: 0% !important;
    }
    img {
      padding: 0;
    }
  }
}
@media screen and (max-width: 989px) {
  .navbar {
    padding: 5% !important;
    height: 100px !important;
    justify-content: center !important;
  }
  .logout-header {
    display: none;
  }
}
