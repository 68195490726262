.security-question-block {
  .content-block {
    max-width: 600px;
    margin: 0 auto;
    width: 600px;
    overflow: auto;
    display: block;
  }
  h2 {
    font-weight: 700;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 13.5px;
    font-size: 18px;
  }
  label {
    display: block;
    font-size: 16px !important;
    font-weight: bold;
    margin-top: 0;
    padding-bottom: 10px;
  }
  .error-block {
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    background: #c23;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    p {
      padding: 0;
    }
    .help-block.error {
      color: #fff;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      margin: 0;
      padding: 15px;
    }
  }
}

@media screen and (max-width: 600px) {
  .security-question-block {
    .content-block {
      max-width: 100%;
      margin: 0 30px;
      width: 100%;
      overflow: auto;
      display: block;
    }
  }
}
