@import '../../assets/styles/style-params';
.recovery-landing-header {
  @include font-heading-level-4-light;
  background: $color-regions-green;
  font-size: 31px;
  padding: 10px 50px;
  margin-bottom: 60px;
}

.main-content {
  background: $color-07-white;
  border-radius: 3px;
  box-shadow: 2px 2px 4px 0 $color-05-medium-light-grey, -2px -2px 4px 0 $color-05-medium-light-grey;
  margin: 40px auto;
  padding: 50px;
  text-align: center;
  width: 100%;

  .input-block {
    width: 600px;
    text-align: left;
    margin-top: 60px;
    margin: 0 auto;
    padding: 0 !important;
  }
  .input-div {
    width: 100%;
  }
  .error-block {
    -ms-flex-align: center;
    align-items: center;
    background: #c23;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    .help-block.error {
      color: #fff;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      margin: 0;
      padding: 10px 15px;
    }
  }
  .button-block {
    //margin-top: 60px;
    width: 600px;
    margin: 30px auto 0;
  }
  .next-button.submit-button {
    width: auto;
    float: none;
  }
}

@media screen and (max-width: 600px) {
  .recovery-landing-header {
    @include font-heading-level-5-light;
    padding: 10px;
  }
  .main-content {
    margin: 0 auto 0;
    padding: 10px;
    text-align: center;
    width: 95%;

    .button-block {
      // margin-top: 60px;
      width: 100%;
      margin: 30px auto 0;
    }
  }
  .recovery-landing.main-content {
    .input-block {
      //margin-top: 30px;
      width: 100%;
    }
    .input-div {
      width: 100%;
    }
    .submit-button {
      width: 100%;
      text-align: center;
    }
  }
}
@media (min-width: 601px) and (max-width: 786px) {
  .input-block {
    margin-left: 10%;
  }

  .input-div {
    width: 80%;
  }
  .error-block {
    width: 80%;
  }
}
