@import '../../../assets/styles/style-params';
.bg-grey {
  background: $color-06-light-grey;
}

.drivers-license-card {
  .payment-header {
    @include font-heading-level-4-dark;

    padding-bottom: 5px;

    span {
      margin-right: 10px;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .date-picker {
    width: 100%;
    padding-left: 45px;
  }

  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border: 1px solid $color-04-grey;
    border-radius: 2px;
    margin-top: 20px;
    text-align: left;
  }

  .position-rel {
    position: relative;
  }

  .chinstrap {
    background-color: red;
    color: white;
    margin-top: -1px;
    padding-left: 10px;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .drivers-license-card {
    border: none;
    margin: 0 auto 20px;
    width: 100%;
    .next-button {
      width: 100% !important;
      // margin-top: 10px;
    }
    .next-button {
      width: 100%;
    }
    .drivers-license-card .payment-header span {
      margin-right: 0;
      width: 100%;
    }
  }
}
