@import '../../assets/styles/style-params';
.page-header {
  @include font-heading-level-4-light;
  background: $color-regions-green;
  font-size: 31px;
  padding: 10px 50px;
  margin-bottom: 60px;
  height: 100px;
  line-height: 31px;
  display: flex;
  .center-align {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    text-align: left;
    width: 100%;
  }
}

@media screen and (max-width: 989px) {
  .page-header {
    @include font-heading-level-5-light;
    font-size: 23px;
    padding: 10px;
    height: 85px;
    margin-bottom: 30px;
    .center-align {
      justify-content: center !important;
      align-items: center;
      display: flex;
    }
  }
}
@media screen and (max-width: 950px) {
  .page-header {
    text-align: center;
  }
}
