@import '../../../assets/styles/style-params';
.bg-grey {
  background: $color-06-light-grey;
}

.bankruptcy-card {
  .payment-header {
    @include font-heading-level-4-dark;

    padding-bottom: 5px;

    span {
      margin-right: 10px;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .date-picker {
    width: 100%;
    padding-left: 45px;
  }

  .form-check-label {
    color: $color-teal;
    font-weight: bold;
  }

  .position-rel {
    position: relative;
  }

  .chinstrap {
    background-color: red;
    color: white;
    margin-top: -1px;
    padding-left: 10px;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .bankruptcy-card {
    border: none;
    margin: 0 auto 20px;
    width: 100%;
    .previous-button {
      width: 100% !important;
      margin-bottom: 0;
    }
    .next-button {
      width: 100% !important;
      // margin-top: 10px;
    }
  }

  .bankruptcy-card .payment-header span {
    margin-right: 0;
    width: 100%;
  }
}

// @media (min-width: 601px) and (max-width: 786px) {
//   .bankruptcy-card {
//     .cancel-button {
//       margin-bottom: 10px;
//       width: 49%;
//       margin-right: 1%;
//     }
//     .next-button {
//       margin-bottom: 10px;
//       width: 49%;
//       margin-right: 1%;
//     }
//   }
// }
