@import '../../assets/styles/style-params';

.account-summary-header {
  @include font-heading-level-4-light;
  background: $color-regions-green;
  font-size: 31px;
  padding: 10px 0;
  margin-bottom: 60px;
}
.landing-container {
  max-width: 1000px;
  width: 1000px;
  overflow: auto;
  display: block;
  margin: 0 auto;
  padding: 15px;
  h1.first-header {
    font-size: 31px;
    margin-bottom: 40px;
    margin-top: 60px;
  }
  h2.second-header {
    font-size: 23px;
    margin-bottom: 40px;
  }
  // .col-xl-12 {
  //   padding: 0;
  // }
}
@media screen and (max-width: 1200px) {
  .account-summary-header {
    @include font-heading-level-5-light;
    padding: 10px 50px !important;
  }
}
@media screen and (max-width: 989px) {
  .account-summary-header {
    padding: 5% !important;
  }
  .landing-container {
    max-width: 100%;
    width: 100%;
    padding: 0;
    h1.first-header {
      font-size: 23px;
      margin-bottom: 30px;
      margin-top: 60px;
    }
    h2.second-header {
      font-size: 18px;
      margin-bottom: 30px;
    }
    .col-xl-12 {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}
