@import '../../../assets/styles/style-params';
.success-card.inner-block {
  float: none;
  margin: none;
  width: 100%;
}
.success-card .one-column-block {
  width: 100%;
}
.success-card .one-column-block .button-group button {
  margin-right: 0;
}

.well {
  background: $color-07-white;
  // border: 1px solid $color-04-grey;
  border-radius: 3px;
  box-shadow: 0px 2px 6px #0404042e;
  margin: 30px auto 50px;
  padding: 30px;
  text-align: left;
  h5 {
    margin-left: 10%;
  }
  .pay-header {
    border-bottom: 1px solid $color-04-grey;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    span {
      margin-right: 10px;
    }
  }
  .success-header {
    margin-top: 10%;
  }
  .last-span {
    margin-right: 10px;
  }
  .success-btn {
    text-align: right;
  }
  .pay-details {
    float: right;
  }

  .account-details {
    p {
      display: inline-block;
      margin-bottom: 5px;
      width: 100%;

      span {
        float: left;
        width: 50%;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }
}
.button-group {
  margin-top: 60px;
  text-align: right;
  button {
    margin-right: 80px;
  }
  :last-child {
    margin-right: 0;
  }
}

.success-next-button {
  @include font-link-text-in-paragraph;
  margin-left: 20px;
  background: $color-regions-green;
  color: $color-07-white;
  border: 1px solid $color-04-grey;
  text-align: center;
  width: auto;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .success-card .one-column-block .button-group {
    margin-top: 30px;
  }
  .well {
    border: none;
    margin: 0 auto 20px;
    .pay-header {
      display: table;
      width: 100%;
      span {
        display: table-row;
        margin-right: 0;
        float: none;
      }
    }
    h5 {
      margin-left: 2%;
    }
    .account-header {
      @include font-heading-level-6-dark;

      span {
        margin-right: 5px;
      }
    }
    .amt-Details {
      display: none;
    }

    .account-details {
      p {
        margin: 0;
        span {
          float: left;
          width: auto;
        }
        span:last-child {
          padding-left: 5px;
        }
      }
    }
  }
  .button-group {
    margin-top: 50px;
    text-align: right;
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    button {
      margin-bottom: 10px;
      margin-right: 0;
      width: 100%;
    }
  }
  .cancel-button {
    width: 100% !important;
  }
  .success-next-button {
    width: 100% !important;
    margin-top: 10px;
    margin-left: 0;
  }
}

// @media (min-width: 601px) and (max-width: 786px) {
//   .cancel-button {
//     margin-bottom: 10px;
//     width: 49%;
//     margin-right: 1%;
//   }
//   .schedule-button {
//     margin-bottom: 10px;
//     width: 49%;
//     margin-right: 1%;
//   }
// }

@media (min-width: 601px) and (max-width: 1200px) {
  .success-card.inner-block {
    border: none;
    margin: 0 auto 20px;
    width: 100%;
    float: none;
    padding: 90px;
    .well {
      .pay-header {
        padding-bottom: 1.5rem;
      }
    }
  }
}
