.regions-alert-notification {
  .alert {
    padding: 15px;
    margin-bottom: 27px;
    border: 2px solid transparent;
    border-radius: 2px;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  }
  .alert-danger {
    background-color: #c23;
    border-color: #a01b28;
    color: #fff;
  }
}
