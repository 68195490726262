@import '../../../assets/styles/style-params';
.bg-grey {
  background: $color-06-light-grey;
}

.payCard {
  width: 100%;
  overflow: auto;
  .inner-block.bg-grey {
    border: 1px solid $color-04-grey;
    box-shadow: none;
    padding: 30px;
  }
  .inner-block div {
    width: 100%;
    float: left;
    .bal-msg {
      width: 60%;
    }
    span {
      width: 50%;
      float: left;
      text-align: left;
    }
    :last-child {
      text-align: right;
    }
  }
  .bal-msg {
    width: 60%;
    text-align: left;
    p {
      font-size: small;
      font-style: italic;
      text-align: left;
    }
  }
}
.unable-to-pay {
  margin-top: 5px;
  text-align: left !important;
  a {
    color: $color-teal;
  }
  width: 100%;
}
.payCard {
  .card-routing-link {
    @include font-link-text-in-paragraph;
    font-weight: bold;
    text-decoration: none;
  }
  .payment-header {
    @include font-heading-level-4-dark;

    padding-bottom: 5px;

    span {
      // margin-right: 10px;
    }
  }
  h3.payment-header {
    @include font-heading-level-4-dark;
    font-size: 18px;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .date-picker {
    width: 100%;
    padding-left: 45px;
  }

  .position-rel {
    position: relative;
  }

  .account-details {
    p {
      display: inline-block;
      margin-bottom: 5px;
      width: 100%;

      span {
        float: left;
        width: 50%;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }
  .chinstrap {
    background-color: red;
    color: white;
    margin-top: -1px;
    padding-left: 10px;
    height: auto;
  }

  .label-div {
    margin-left: -27px;
    margin-bottom: -5px;
  }
}

@media screen and (max-width: 600px) {
  .payCard {
    border: none;
    margin: 0 auto 20px;
    width: 100%;
    .account-header {
      @include font-heading-level-6-dark;

      span {
        //margin-right: 5px;

        img {
          height: 15px;
          float: right;
        }
      }
    }

    .cancel-button {
      width: 100% !important;
      margin-bottom: 0;
    }
    .next-button {
      width: 100% !important;
      // margin-top: 10px;
    }
    .account-details {
      p {
        margin: 0;
        span {
          float: left;
          width: auto;
        }
        span:last-child {
          padding-left: 5px;
        }
      }
    }
  }
  .cancel-button {
    margin-bottom: 20px;
    width: 100%;
  }
  .next-button {
    width: 100%;
  }
  .payCard .inner-block div {
    width: 100%;
    float: left;
    span {
      width: 62%;
      float: left;
    }
    :last-child {
      text-align: right;
      width: 38%;
    }
  }
  .unable-to-pay {
    width: 100% !important;
  }
  .payCard .payment-header span {
    margin-right: 0;
    width: 100%;
  }
  // .payCard .inner-block div span {
  //   width: 50%;
  //   float: left;
  // }
}

@media (min-width: 601px) and (max-width: 786px) {
  .payCard .inner-block div span {
    width: 50%;
    float: left;
  }
}
@media screen and (max-width: 1200px) {
  .payCard {
    border: none;
    margin: 0 auto 30px;
    width: 100%;
    .two-column-layout .column-two,
    .two-column-layout .column-one {
      padding: 15px 0px;
    }

    .inner-block.bg-grey {
      padding: 15px;
    }
  }
}
