@import '../../../assets/styles/style-params';
.bg-grey {
  background: $color-06-light-grey;
}
.recovery-card {
  margin: 0 auto 50px;
  padding: 90px;
  width: 100%;
  background: $color-07-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0404042e;
  border-radius: 3px;
  opacity: 1;
}
.payment-card .inner-block div {
  width: 100%;
  float: left;
  span {
    width: 150px;
    float: left;
  }
  :last-child {
    text-align: right;
  }
}
.unable-to-pay {
  margin-top: 5px;
  text-align: left !important;
}
.account-card {
  // background: $color-07-white;
  // // border: 1px solid $color-04-grey;
  // border-radius: 3px;
  // box-shadow: 0px 2px 6px #0404042e;
  // margin: 0 auto 50px;
  // padding: 10px 15px;
  // width: 80%;
  .card-routing-link {
    @include font-link-text-in-paragraph;
    font-weight: bold;
    text-decoration: none;
  }
  .payment-header {
    @include font-heading-level-4-dark;

    padding-bottom: 5px;

    span {
      margin-right: 10px;
    }
  }

  .img-wrapper.recoveryWrapper {
    left: -2px;
    position: relative;
    top: 0px;
    width: 15px;
  }
  .position-rel {
    position: relative;
  }
  .image-wrapper-div {
    display: block;
    float: left;
    width: 30px;
    height: 38px;
    background: #e9ecef;
    position: absolute;
    top: 0;
    border-radius: 3px 0 0 3px;
    border: 1px solid $color-04-grey;
  }
  .account-details {
    p {
      display: inline-block;
      margin-bottom: 5px;
      width: 100%;

      span {
        float: left;
        width: 50%;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }
  .chinstrap {
    background-color: #c23;
    color: white;
    margin-top: -1px;
    padding: 18px 30px;
    height: auto;
  }

  .label-div {
    margin-left: -27px;
    margin-bottom: -5px;
  }
}

@media screen and (max-width: 600px) {
  .recovery-card {
    padding: 30px;
  }
  .account-card {
    border: none;
    margin: 0 auto 0;
    width: 95%;
    .account-header {
      @include font-heading-level-6-dark;

      span {
        //margin-right: 5px;

        img {
          height: 15px;
          float: right;
        }
      }
    }
    .amt-Details {
      display: none;
      span {
        margin-right: 5px;
      }
    }
    .cancel-button {
      width: 100% !important;
      margin-bottom: 0;
      float: none !important;
    }
    .next-button {
      width: 100% !important;
      margin-top: 10px;
      float: none !important;
    }
    .two-column-layout .column-two .column-two-buttons {
      width: 100%;
      padding-top: 20px;
    }
    .account-details {
      p {
        margin: 0;
        span {
          float: left;
          width: auto;
        }
        span:last-child {
          padding-left: 5px;
        }
      }
    }
  }
  .cancel-button {
    margin-bottom: 20px;
    width: 100%;
  }
  .next-button {
    width: 100%;
  }
  .payment-card .inner-block div {
    width: 100%;
    float: left;
    span {
      width: 50%;
      float: left;
    }
    :last-child {
      text-align: right;
    }
  }
  .account-card .payment-header span {
    margin-right: 0;
    width: 100%;
  }
}
