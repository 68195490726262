@import '../../assets/styles/style-params';
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border: 1px solid $color-04-grey;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  overflow: auto;
  text-align: left;
}

.login-page {
  a {
    color: $color-teal;
    text-decoration: none;
  }
  h2 {
    font-weight: 700;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 13.5px;
    font-size: 18px;
  }
  h3 {
    font-size: 16px !important;
    margin-top: 0;
    padding-bottom: 10px;
  }
  .control-label {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 15px;
  }
  .error-block {
    -ms-flex-align: center;
    align-items: center;
    background: #c23;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    .help-block.error {
      color: #fff;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      margin: 0;
      padding: 10px 15px;
    }
  }
  .instructions {
    display: block;
    margin-bottom: 10px;
    color: #767676;
    background: #f9f9f9;
    border: 1px solid #ccc;
    color: #333;
    font-size: 16px;
    margin-bottom: 0;
    padding: 10px 15px;
  }
  label:after {
    content: '';
    background-color: #8b0;
    border-radius: 50% 50%;
    display: inline-block;
    height: 5px;
    position: relative;
    right: -0.5em;
    top: -0.5em;
    width: 5px;
  }
  .error label:after {
    background-color: #c23;
  }
  .enroll-button {
    @include font-link-text-in-paragraph;
    background-color: $color-teal;
    border: 1px solid $color-teal;
    color: $color-07-white;
    float: right;
    text-align: center;
    height: 54px;
    border-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 30px;
    text-decoration: none;
    line-height: 32px;
    font-size: 23px;
  }
}
