@import '../../../assets/styles/style-params';
.accounts-summary {
  .account-card {
    margin: 0 auto 50px;
    padding: 50px;
    width: 100%;
    background: $color-07-white 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0404042e;
    // border: 1px solid $color-02-dark-grey;
    border-radius: 3px;
    opacity: 1;
    .card-routing-link {
      @include font-link-text-in-paragraph;
      font-weight: bold;
      text-decoration: none;

      padding-top: 0;
      line-height: 18px;
      text-align: right;
      cursor: pointer;
    }
    .card-routing-link:hover {
      text-decoration: underline;
    }
    .card-disabled-link {
      @include font-link-text-in-paragraph;
      font-weight: bold;
      text-decoration: none;
      color: black;
      padding-top: 0;
      line-height: 18px;
      text-align: right;
    }
    .account-header {
      @include font-heading-level-4-dark;
      float: left;
      padding-top: 6px;
      //margin-bottom: 30px;
      width: 100%;

      span {
        // margin-right: 5px;

        img {
          height: 25px;
        }
      }
    }
    .account-links {
      a {
        width: 100%;
      }
      .account-header {
        text-align: right;
        float: none;
        margin-bottom: 50px;
      }
    }
    .showDaysPastDue {
      display: none;
    }
    .account-details {
      .account-header {
        height: 50px;
        margin-bottom: 30px;
      }
      p {
        float: left;
        margin-bottom: 5px;
        line-height: 26px;
        width: 100%;

        span {
          width: 165px;
          float: left;
        }
      }
      .bal-msg {
        width: 60%;
        p {
          font-size: small;
          font-style: italic;
          text-align: left;
        }
      }
    }
  }
}
.icon-padding {
  padding: 0.0rem 0.2rem !important;
}
@media screen and (max-width: 989px) {
  .accounts-summary {
    .account-card {
      border: none;
      margin: 0 auto 20px;
      padding: 15px;
      .card-routing-link {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: left;
      }
      .card-routing-link:first-child {
        margin-top: 10px;
      }
      .card-disabled-link {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: left;
        color: black;
      }
      .card-disabled-link:first-child {
        margin-top: 10px;
      }
      .show-header {
        display: none;
      }
      .showDaysPastDue {
        display: block;
      }
      .account-header {
        @include font-heading-level-6-dark;
        font-size: 18px;
        border-bottom: none;
        height: auto;
        width: 100%;
        margin-bottom: 10px;

        span {
          //margin-right: 5px;

          img {
            height: 15px;
            float: right;
          }
        }
      }
      .account-links {
        // border-top: 1px solid $color-04-grey;
        margin-top: 10px;
        a {
          width: 100%;
        }
        .account-header {
          text-align: left;
        }
      }
      .account-details {
        border-bottom: none;
        border-right: none;
        .account-header {
          height: auto;
          margin-bottom: 10px;
        }
        p {
          margin: 0;
          width: 100%;
          span {
            float: left;
            width: 54% !important;
            margin-right: 1%;
          }
          span:last-child {
            width: 45% !important;
            padding-left: 0;
            text-align: right;
            margin-right: 0;
          }
        }
      }
    }
  }
}
