@import '../../assets/styles/style-params';
.custom-footer {
  background-color: $color-07-white;
  bottom: 0;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;
  .footer-container {
    width: 100%;
  }
  a#callRegions {
    color: $color-teal;
    text-decoration: none;
  }
  .footer-links {
    padding: 30px;
    li {
      @include font-link-text-in-paragraph;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      width: 20%;

      a {
        color: $color-teal;
      }
    }
    li:first-child {
      width: 15%;
    }
    li:last-child {
      width: 25%;
    }
  }
  p {
    line-height: 35px;
    margin-bottom: 0;
  }
  .footer-logos {
    span:first-child > img {
      padding-top: 10px;
      margin-right: 50px;
      width: 35px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .recovery-footer {
    margin-top: 30px;
  }
  .custom-footer {
    font-size: 14px;
    padding: 5px;
    clear: both;
    .footer-links {
      padding: 0;
      li {
        font-size: 14px;
        margin-bottom: 5px;
        width: 50%;
      }
      li:nth-child(odd) {
        text-align: right;
        padding-right: 10px;
      }
      li:nth-child(even) {
        text-align: left;
      }
      li:first-child {
        width: 50%;
      }
      li:last-child {
        width: 100%;
        text-align: center;
      }
    }
    p {
      line-height: 20px;
      margin-bottom: 1rem;
    }
  }
}

@media screen and (min-width: 1400px) {
  .custom-footer {
    .footer-container {
      width: 1370px;
      margin: 0 auto;
    }
  }
}
