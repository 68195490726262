@import '../../assets/styles/style-params';

.on-clicking-div {
  margin-top: 10px;
}
.terms-block {
  background: $color-07-white;
  border-radius: 3px;
  box-shadow: 0px 2px 6px #0404042e;
  margin: 0px auto 60px;
  padding: 90px;
  text-align: left;
  width: 100%;
  form {
    float: right;
  }
  p {
    font-size: 18px;
  }
  .payment-details-header {
    font-size: 18px;
    font-weight: bold;
  }
  .payment-details {
    margin-bottom: 0;

    // span {
    //   // font-weight: bold;
    // }
    span:first-child {
      float: left;
      font-weight: bold;
      margin-right: 15px;
      text-align: left;
      width: 180px;
    }
  }
  .third-para {
    margin-top: 30px;
  }

  .button-group {
    margin-top: 60px;
    text-align: right;
    button {
      margin-right: 80px;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .checkbox-label {
    margin-left: 5px;
    font-weight: normal;
  }
  .authorize-button {
    background: $color-regions-green;
  }
}

@media screen and (max-width: 600px) {
  .terms-block {
    margin: 0 auto;
    padding: 15px;
    text-align: left;
    width: 100%;
    .button-group {
      margin-top: 50px;
      text-align: right;
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      button {
        margin-bottom: 10px;
        margin-right: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
@media (min-width: 601px) and (max-width: 989px) {
  .terms-block {
    width: 95%;
    .button-group button {
      margin-right: 10px;
    }
  }
}
@media (min-width: 990px) and (max-width: 1200px) {
  .terms-block {
    border: none;
    margin: 0 auto 20px;
    width: 90%;
  }
}
