@import '../assets/styles/style-params';
body {
  font-family: Source Sans Pro !important;
  background: $color-06-light-grey !important;
  font-size: 18px !important;
}
textarea.form-control {
  height: auto !important;
}
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--selected {
  background-color: $color-regions-green !important;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select::-ms-expand {
  display: none;
}
.selectToggleIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 15px;
  z-index: 0;
  pointer-events: none;
}
.well p:last-child {
  margin-bottom: 0;
}
.confirmation-number {
  visibility: hidden;
  height: 0;
  margin: 0;
}
.logout-app.mobile-signout-button {
  display: none;
}
.next-button.go-to-login-button {
  float: none;
  margin: 30px auto;
  width: auto !important;
}
h1.primary-header {
  font-size: 31px;
}
h2.secondary-header {
  font-size: 23px;
}
.form-control {
  font-size: 18px !important;
  height: 50px !important;
}
.input-group-text {
  width: 35px;
  background-color: $color-07-white !important;
  border: 2px solid $color-04-grey !important;
  border-right: none !important;
  color: #747474;
  font-weight: 600;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Custom check box CSS
.form-check {
  padding-left: 0 !important;
}
.form-check.regions-input-checkbox {
  display: flex;
}
.form-check-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: $color-07-white;
  border: 1px solid $color-04-grey;
  box-shadow: inset 0px 0px 0px 4px #fff;
  border-radius: 0.25rem;
}

.form-check-label input:checked ~ .checkmark {
  background-color: $color-07-white;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  left: 10px;
  top: 5px;
  width: 8px;
  height: 14px;
  border: solid $color-life-green;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
// Custom checkbox CSS

// Custom radio buttons CSS
.form-check-label.radio-check {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top: 2px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label.radio-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioDot {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $color-07-white;
  border: 1px solid $color-04-grey;
  border-radius: 50%;
  margin-top: 3px;
}

.form-check-label.radio-check input:checked ~ .radioDot {
  background-color: $color-07-white;
}

.form-check-label.radio-check .radioDot::after {
  content: '';
  position: absolute;
  display: none;
}

.form-check-label.radio-check input:checked ~ .radioDot::after {
  display: block;
}

.form-check-label.radio-check .radioDot::after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $color-life-green;
}
// Custom radio buttons CSS

.mb30 {
  margin-bottom: 30px !important;
}
.button-layout {
  margin-top: 60px;
}
.third-button.save-button {
  width: 100%;
}
.spinner-container {
  position: fixed;
  width: 100%;
  height: 100vh; //100%;;
  z-index: 1200;
  .spinner {
    position: fixed;
    left: calc(50% - 57px);
    top: 50%;
    z-index: 1050;
  }
}
.two-column-layout {
  width: 100%;
  .column-one {
    float: left;
    max-width: 470px;
    padding: 0 15px;
    width: 470px;
  }
  .column-two {
    float: right;
    max-width: 470px;
    padding: 0 15px;
    width: 470px;
    .column-two-buttons {
      padding-top: 60px;
      :first-child {
        float: left;
      }
      :last-child {
        float: right;
      }
    }
  }
  .well {
    padding: 30px !important;
  }
}
.one-column-layout {
  background: $color-07-white;
  border-radius: 3px;
  box-shadow: 2px 2px 4px 0 $color-05-medium-light-grey, -2px -2px 4px 0 $color-05-medium-light-grey;
  margin: 40px auto;
  padding: 50px;
  text-align: left;
  width: 100%;
}
// Changes regarding input field Paddings
label,
legend {
  margin-bottom: 0 !important;
  padding-bottom: 10px;
}
.input-group {
  border-radius: 2px;
  margin-bottom: 0;
}
.form-control {
  border: 2px solid $color-04-grey !important;
  padding-bottom: 30px;
}
.form-group {
  margin-bottom: 0 !important;
  padding-bottom: 30px !important;
}
fieldset.form-group {
  margin-bottom: 0 !important;
  padding-bottom: 30px !important;
}
.connected-inputs {
  .form-group {
    margin-bottom: 0 !important;
    padding-bottom: 20px !important;
  }
}
.connected-fields.form-group {
  padding-bottom: 20px !important;
}
.connected-groups {
  .form-group:nth-child(odd) {
    padding-bottom: 10px !important;
  }
  .form-group:nth-child(even) {
    padding-bottom: 40px !important;
  }
}
// Changes regarding input field Paddings
h2.payment-header {
  font-size: 23px;
}
h3.payment-header {
  font-size: 18px;
}
.page-content-container {
  background: $color-07-white 0% 0% no-repeat padding-box;
  border: 2px solid $color-05-medium-light-grey;
  border-radius: 2px;
  box-shadow: 0px 2px 10px #00000009;
  margin: 0 auto 60px;
  padding: 90px;
  width: 100%;
  max-width: 1350px;
}
.regions-container {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
#root > .container-fluid {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.secondary-header {
  @include font-heading-level-4-dark;
  padding-bottom: 5px;
}
.inner-block {
  background: $color-07-white;
  border-radius: 3px;
  box-shadow: 2px 2px 4px 0 $color-05-medium-light-grey, -2px -2px 4px 0 $color-05-medium-light-grey;
  display: block;
  margin: 40px auto;
  padding: 60px;
  text-align: left;
  width: 100%;
  overflow: auto;
}
.cancel-button {
  @include font-link-text-in-paragraph;
  background: $color-07-white;
  border: 1px solid $color-04-grey;
  font-size: 23px;
  height: 54px;
  text-align: center;
  width: 130px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 30px;
  text-decoration: none;
  margin-right: 20px;
}
.third-button {
  @include font-link-text-in-paragraph;
  background: $color-07-white;
  border: none;
  font-size: 23px;
  height: 54px;
  text-align: left;
  width: auto;
  border-radius: 3px;
  cursor: pointer;
  // font-weight: bold;
  padding: 10px 0px;
  margin-right: 20px;
}
.next-button {
  @include font-link-text-in-paragraph;
  background: $color-regions-green;
  float: right;
  height: 54px;
  font-size: 23px;
  color: $color-07-white;
  border: 1px solid $color-04-grey;
  text-align: center;
  width: 130px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 30px;
  text-decoration: none;
}
.hardship-button {
  @include font-link-text-in-paragraph;
  background: $color-regions-green;
  font-size: 23px;
  height: 54px;
  float: right;
  color: $color-07-white;
  border: 1px solid $color-04-grey;
  text-align: center;
  width: 260px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 30px;
  text-decoration: none;
}
.next-button.extra-width {
  width: auto;
}
.schedule-button,
button.next-button.schedule-button {
  width: auto;
}
.submit-cancel-button-group {
  margin-top: 60px;
}
.next-button.begin-button {
  width: 255px !important;
}
button.cancel-button.save-button {
  width: 100%;
  margin-top: 15px;
  margin-right: 0;
}
.one-column-block {
  max-width: 600px;
  margin: 0 auto;
}
.one-column-block.max-width-block {
  max-width: 100%;
}
.form-check-label {
  color: $color-teal;
  font-weight: bold;
  padding-bottom: 0;
}
.react-datepicker-wrapper {
  width: 100%;
  input {
    padding-left: 35px;
  }
}
.date-picker {
  width: 100%;
  padding-left: 35px;
}
.img-wrapper {
  width: 18px;
}
.position-rel {
  position: relative;
}

.image-wrapper-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 50px;
  background: $color-07-white;
  position: absolute;
  top: 0;
  border-radius: 3px 0 0 3px;
  border: 2px solid $color-04-grey;
}
.chinstrap {
  background-color: $color-red !important;
  color: $color-07-white !important;
  margin-top: -1px;
  padding: 18px 30px !important;
  height: auto !important;
}
.income-well {
  background-color: $color-06-light-grey;
  border: 1px solid $color-05-medium-light-grey;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  margin-top: -17px;
  padding: 30px;
}
.regions-input-checkbox {
  input[type='checkbox'] {
    box-sizing: border-box;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 0;
    width: 20px;
  }
  input[type='checkbox']:checked {
    color: $color-life-green;
  }
  .checkbox-label {
    @include font-link-text-default;
    display: inline;
    font-weight: 500;
  }
}

@media screen and (max-width: 600px) {
  .spinner-container .spinner {
    left: calc(50% - 30px);
    top: calc(50% - 60px);
  }
  .two-column-layout .well {
    padding: 15px !important;
    p:last-child {
      margin-bottom: 0;
    }
  }
  umn-layout .column-two .column-two-buttons:first-child {
    width: 100%;
  }
  .two-column-layout .column-two .column-two-buttons:last-child {
    width: 100%;
    padding-top: 10px;
    button {
      margin-right: 0;
    }
  }
  .two-column-layout .column-two .column-two-buttons {
    width: 100%;
  }
  .two-column-layout .column-two .column-two-buttons:last-child {
    padding-top: 10px;
  }
  .two-column-layout {
    width: 100%;
    .column-two {
      .column-two-buttons {
        padding-top: 30px;
        .cancel-button {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
      .column-two-buttons.float-left {
        padding-top: 10px;
      }
    }
    .well {
      padding: 15px !important;
    }
  }
  .third-button.save-button {
    text-align: center;
  }
  .chinstrap {
    padding: 15px !important;
  }
  .button-layout {
    width: 100%;
  }
  .button-layout:nth-child(2) {
    margin-top: 10px;
  }
  .button-layout:last-child {
    margin-top: 10px;
  }
  .page-content-container {
    border-radius: 3px;
    box-shadow: 0px 2px 6px #0404042e;
    margin: 0 auto 30px;
    padding: 15px;
  }
  .cancel-button {
    margin-bottom: 20px;
    width: 100%;
  }
  .next-button {
    width: 100%;
  }
  .schedule-button {
    width: 100%;
  }
  .next-button.schedule-button {
    padding: 10px 10px;
  }
  .inner-block {
    margin: 0 auto;
    padding: 10px;
    text-align: left;
    width: 100%;
  }
  .next-button.extra-width {
    width: 100%;
  }
  .two-column-layout {
    width: 100%;
    .column-one {
      float: left;
      max-width: 100%;
      padding: 30px 15px;
      width: 100%;
    }
    .column-two {
      float: right;
      max-width: 100%;
      padding: 30px 15px;
      width: 100%;
    }
  }
  ul {
    padding: 0;
  }
  .well {
    ul {
      padding-left: 10px;
    }
  }
  .next-button.begin-button,
  .schedule-button,
  button.next-button.schedule-button {
    width: 100% !important;
    font-size: 19px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  button {
    font-size: 19px !important;
  }
  .account-links .account-header {
    border-bottom: none !important;
  }
}
@media (min-width: 601px) and (max-width: 1200px) {
  .schedule-button {
    width: auto;
  }
  .inner-block {
    margin: 0 auto;
    padding: 10px;
    text-align: left;
    width: 100%;
  }
  .account-links .account-header {
    border-bottom: none !important;
    border-bottom-width: initial !important;
    border-bottom-style: none !important;
    border-bottom-color: initial !important;
  }
  .two-column-layout {
    width: 100%;
    .column-one {
      float: left;
      max-width: 100%;
      padding: 15px;
      width: 100%;
    }
    .column-two {
      float: right;
      max-width: 100%;
      padding: 15px;
      width: 100%;
    }
  }
  ul {
    padding: 0;
  }
  .well {
    ul {
      padding-left: 10px;
    }
  }
}
@media screen and (min-width: 1400px) {
  .regions-container {
    max-width: 1350px;
  }
}
@media screen and (max-width: 989px) {
  .logout-app.mobile-signout-button {
    display: block;
  }
  .submit-cancel-button-group button {
    margin-bottom: 10px;
  }
  .mobile-signout-button {
    @include font-link-text-in-paragraph;
    background: $color-07-white;
    border: 1px solid $color-04-grey;
    border-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    margin: 30px 0;
  }
}
